<template>
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5921 3.47519C1.5921 2.86911 1.80495 2.36911 2.23062 1.97519C2.65629 1.58124 3.20968 1.38428 3.89075 1.38428C4.55968 1.38428 5.10088 1.5782 5.5144 1.9661C5.94007 2.3661 6.15291 2.8873 6.15291 3.52973C6.15291 4.11155 5.94616 4.59639 5.53264 4.98428C5.10697 5.38428 4.54751 5.58428 3.85426 5.58428H3.83602C3.1671 5.58428 2.62589 5.38428 2.21237 4.98428C1.79885 4.58428 1.5921 4.08124 1.5921 3.47519ZM1.82926 19.3843V7.23882H5.87926V19.3843H1.82926ZM8.12318 19.3843H12.1732V12.6025C12.1732 12.1782 12.2218 11.8509 12.3191 11.6206C12.4894 11.2085 12.7478 10.86 13.0945 10.5752C13.4411 10.2903 13.8759 10.1479 14.3989 10.1479C15.761 10.1479 16.4421 11.0631 16.4421 12.8934V19.3843H20.4921V12.4206C20.4921 10.6267 20.0664 9.26609 19.2151 8.33882C18.3637 7.41155 17.2387 6.94791 15.8401 6.94791C14.2712 6.94791 13.0489 7.62064 12.1732 8.96609V9.00246H12.1549L12.1732 8.96609V7.23882H8.12318C8.1475 7.62669 8.15967 8.83275 8.15967 10.857C8.15967 12.8812 8.1475 15.7237 8.12318 19.3843Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconLinkedIn'
}
</script>
